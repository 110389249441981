<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Raport concedii
    el-card
      el-form(label-position="top" :inline="false" label-width="100%"  :model='Filters'  ref='my-form' )
        el-row(:gutter="24")
          el-col(:span='6')
            el-form-item( label="Data start" )
              el-date-picker( v-model="Filters.DataStart" value-format="yyyy-MM-dd" format="yyyy-MM-dd")
          
      div.top50
        el-button(type='success' @click="refresh_info") Filtreaza
        el-button(type='danger' @click="reset()") Reset

    el-table( :data="Results" )
        el-table-column( label="Nr. Document" prop="Id" min-width='100')
        el-table-column( label="Nume"         min-width='300')
            template(slot-scope="prop")
                el-tooltip( v-if='prop.row.IsEnded' content="Concediul s-a terminat")
                    el-tag( type='success') {{prop.row.NumeAngajat}}
                el-tooltip( v-if='prop.row.IsNow' content="Este in concediu acum")
                    el-tag( type='danger' ) {{prop.row.NumeAngajat}}
                el-tooltip( v-if='!prop.row.IsEnded && !prop.row.IsNow' content="Concediul urmeaza")
                    p {{prop.row.NumeAngajat}}
        el-table-column( prop="StartConcediu"     label="Start concediu" min-width='200')
        el-table-column( prop="SfarsitConcediu"   label="Sfarsit concediu"  min-width='200')
        el-table-column( prop="Tip"               label="Tip"  )
      
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';
import moment from 'moment';

export default {
  name: "angajati",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'titlu-pagina': TitluPagina
  },
  data () {
    return { 
      Results: [],
      base_url: '',
      Filters: { DataStart: '' },
    }
  },
  methods: {
    async get_info(){
        this.refresh_info();
    },
    async refresh_info(){
      var response        = await this.post("raport_concedii/refresh_info", { Filters: this.Filters } );
      this.Results        = response.Results;
      //
      this.select_menu_item('raport-concedii');
    },
    reset(){
      this.Filters = { DataStart: moment().subtract( 15, 'days').format('YYYY-MM-DD') };
      this.refresh_info();
    },
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    
    this.base_url = settings.get_base_url();
    this.reset();
    this.get_info();
  }
};
</script>

<style lang="less" scoped>

  .top50{
    margin-top: 20px;
  }
 
</style>
